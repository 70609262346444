/**!
 * SmartAppBanner v1.0.3.20190610
 */

class SmartAppBanner {
	KEY_CLOSED = 'sab-closed';
	TYPE_ANDROID = 'android';
	TYPE_IOS = 'ios';
	containerId = 'smartAppBanner';
	_defaults = {
		type: undefined,
		data: {
			appId: '',
			title: '',
			subtitle: '', // subtitle === author
			author: '',
			desc: '', // desc === inStore
			inStore: '',
			link: '',
			iconUrl: '',
			price: '',
			button: 'VIEW'
		},
		onClose: null,
		onCTAClick: null,
		appStoreLanguage: 'en',
		googlePlayParams: undefined,
		inAppStore: 'On the App Store', // Text of price for iOS
		inGooglePlay: 'In Google Play', // Text of price for Android
		layer: false, // Display as overlay layer or slide down the page
		appendToSelector: 'body', //Append the banner to a specific selector
		iconGloss: null, // Force gloss effect for iOS even for precomposed
		template: null,
		bodyClass: 'sab-opened' // Added this class name to body when SmartAppBanner shown
	};

	constructor(options = {}) {
		this.config = {...this._defaults, ...options};
		if (options.data)
		{
			this.config.data = {...this._defaults.data, ...options.data};
		}
		this.config.data.author = this.config.data.subtitle || this.config.data.author;
		this.config.data.inStore = this.config.data.desc || this.config.data.inStore;
		this.config.type = this.config.type || this._getDeviceType();

		if (this._isAvailable()) {
			this._initData();
			this._initActions();

			this._renderUI();
		}
	}

	_isAvailable() {
		let isAvailableType = !!this.config.type,
				isNotWebStandalone = !navigator.standalone,// not standalone web
				hasNotBeenClosed = !localStorage || !localStorage.getItem(this.KEY_CLOSED);
		return isAvailableType && this._isAvailableBrowser() && isNotWebStandalone && hasNotBeenClosed && !this._isWebview();
	}

	_initData() {
		// title, author, iconUrl, price
		let metaElementSelector = this.config.type === this.TYPE_ANDROID ? 'meta[name="google-play-app"]' : (this.config.type === this.TYPE_IOS ? 'meta[name="apple-itunes-app"]' : '');
		if (!!metaElementSelector) {
			let metaElement = document.querySelector(metaElementSelector);
			if (!metaElement) {
				return;
			}
			let parsedMetaContent = /app-id=([^\s,]+)/.exec(metaElement.getAttribute('content'));
			if (parsedMetaContent) {
				this.config.data.appId = parsedMetaContent[1];
			}
			this.config.data.title = this.config.data.title
					|| (metaElement.getAttribute('title') || document.getElementsByTagName('title')[0].text.replace(/\s*[|\-·].*$/, ''));
			this.author = this.config.data.author
					|| (metaElement.getAttribute('author') || (document.querySelector('meta[name="author"]') ? document.querySelector('meta[name="author"]').getAttribute('content') : window.location.hostname));

			this.config.data.iconUrl = this.config.data.iconUrl || metaElement.getAttribute('icon-url');
			this.config.data.price = this.config.data.price || metaElement.getAttribute('price');
		}
		// link
		if (!this.config.data.link && !!this.config.data.appId) {
			if (this.config.type === this.TYPE_ANDROID) {
				this.config.data.link = `market://details?id=${this.config.data.appId}`;
				if (this.config.googlePlayParams) {
					this.config.data.link += `&referrer=${this.config.googlePlayParams}`;
				}
			} else if (this.config.type === this.TYPE_IOS) {
				this.config.data.link = `https://itunes.apple.com/${this.config.appStoreLanguage}/app/id${this.config.data.appId}`;
			}
		}
		// inStore
		if (this.config.data.price && !this.config.data.inStore) {
			let storeText = '';
			if (this.config.type === this.TYPE_ANDROID) {
				storeText = this.config.inGooglePlay;
			} else if (this.config.type === this.TYPE_IOS) {
				storeText = this.config.inAppStore;
			}
			this.config.data.inStore = `${this.config.data.price} - ${storeText}`;
		}
		// iconUrl
		if (!this.config.data.iconUrl) {
			let iconURL = '';
			this.config.iconGloss = this.config.iconGloss == null ? (this.config.type === this.TYPE_IOS) : this.config.iconGloss;
			if (document.querySelector('link[rel="apple-touch-icon-precomposed"]')) {
				iconURL = document.querySelector('link[rel="apple-touch-icon-precomposed"]').getAttribute('href');
				if (this.config.iconGloss == null) {
					this.config.iconGloss = false;
				}
			} else if (document.querySelector('link[rel="apple-touch-icon"]')) {
				iconURL = document.querySelector('link[rel="apple-touch-icon"]').getAttribute('href');
			} else if (document.querySelector('meta[name="msApplication-TileImage"]')) {
				iconURL = document.querySelector('meta[name="msApplication-TileImage"]').getAttribute('content');
			} else if (document.querySelector('meta[name="msapplication-TileImage"]')) {
				// Redundant because ms docs show two case usages.
				iconURL = document.querySelector('meta[name="msapplication-TileImage"]').getAttribute('content');
			}
			this.config.data.iconUrl = iconURL;
		}
	}

	_initActions() {
		let onClose = () => {
			!!localStorage && localStorage.setItem(this.KEY_CLOSED, '1');

			this._hide();
		};
		if (!this.config.onClose) {
			this.config.onClose = onClose;
		} else {
			let tempClose = this.config.onClose;
			this.config.onClose = () => {
				onClose();
				tempClose();
			}
		}
		if (!this.config.onCTAClick)
		{
			this.config.onCTAClick = () => {};
		}
	}

	_getTemplate() {
		let {title, author, link, inStore, button, iconUrl} = this.config.data;
		if (!this.config.template) {
			return `
				<div id="${this.containerId}" class="${this.config.type}" style="position: ${this.config.layer ? 'absolute' : 'static'}">
					<div class="sab-container center-container">
						<div class="sab-close center-wrapper">&times;</div>
						<div class="sab-icon-wrapper center-wrapper">
							<span class="sab-icon center-wrapper ${(iconUrl && this.config.iconGloss) ? 'gloss' : ''} ${iconUrl ? '' : 'no-icon'}" style="${iconUrl ? ('background-image: url(' + iconUrl + ')') : ''}"></span>
						</div>
						<div class="sab-info center-wrapper">
							<strong>${title}</strong>
							<span>${author}</span>
							<span>${inStore}</span>
						</div>
						<a href="${link}" class="sab-button center-wrapper">
							<span>${button}</span>
						</a>
					</div>
				</div>`
		} else {
			return this.config.template
					.replace('{{type}}', this.config.type)
					.replace('{{title}}', title)
					.replace('{{author}}', author)
					.replace('{{link}}', link)
					.replace('{{inStore}}', inStore)
					.replace('{{button}}', button);
		}
	}

	_renderUI() {
		let appendElement = document.querySelector(this.config.appendToSelector),
				dom = this._getTemplate();
		if (appendElement) {
			let insertPosition = this.config.layer ? 'beforeend' : 'afterbegin';
			appendElement.insertAdjacentHTML(insertPosition, dom);

			this.containerEl = document.getElementById(this.containerId);

			// Custom template
			if (this.config.template) {
				this.containerEl.style.position = this.config.layer ? 'absolute' : 'static';
				let sabIconEl = this.containerEl.querySelector('sab-icon');
				if (this.config.data.iconUrl) {
					sabIconEl.style.backgroundImage = `url('${this.config.data.iconUrl}')`;
				} else {
					this._addClass(this.containerEl, 'no-icon');
				}
			}

			if (this.config.type === this.TYPE_IOS && /iPad/.test(navigator.userAgent))
			{
				this._addClass(this.containerEl, 'is-ipad');
			}

			this._addClass(document.body, this.config.bodyClass);

			this._bindEvents();
		}
	}

	_bindEvents()
	{
		if (!!this.containerEl)
		{
			let closeEl = this.containerEl.querySelector('.sab-close');
			if (closeEl)
			{
				closeEl.addEventListener('click', this.config.onClose.bind(this));
			}
			let buttonEl = this.containerEl.querySelector('.sab-button');
			if (buttonEl)
			{
				buttonEl.addEventListener('click', this.config.onCTAClick.bind(this));
			}
		}
	}

	_getDeviceType() {
		const UA = navigator.userAgent;
		let type = undefined;

		if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
			type = this.TYPE_IOS;
		} else if (UA.match(/Android/i) !== null) {
			type = this.TYPE_ANDROID;
		}
		return type;
	}

	_isWebview() {
		let useragent = navigator.userAgent;
		let rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari\/)', 'Android.*(wv|\.0\.0\.0)'];
		let regex = new RegExp(`(${rules.join('|')})`, 'ig');
		return Boolean(useragent.match(regex));
	}

	_isAvailableBrowser() {
		let userAgent = navigator.userAgent,
				isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream,
				isAndroid = /(android)/i.test(userAgent);
		return isIOS || isAndroid;
	}

	_hide() {
		if (this.containerEl) {
			this.containerEl.style.display = 'none';
		}
		this._removeClass(document.body, this.config.bodyClass);
	}

	_addClass(element, className)
	{
		if (document.createElement('div').classList)
		{
			this._addClass = (element, className) => {
				element.classList.add(className);
			};
		}
		else
		{
			this._addClass = (element, className) => {
				element.className += ` ${className}`;
			};
		}
		this._addClass(element, className);
	}

	_removeClass(element, className)
	{
		if (document.createElement('div').classList)
		{
			this._removeClass = (element, className) => {
				element.classList.remove(className);
			};
		}
		else
		{
			this._removeClass = (element, className) => {
				element.className = element.className.replace(new RegExp('(\\s|^)' + className + '(\\s|$)'), ' ');
			};
		}
		this._removeClass(element, className);
	}
}

export default SmartAppBanner